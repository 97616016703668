import { extend } from 'vee-validate';
import { required, max } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Masukkan {_field_}.',
});

extend('max', {
  ...max,
  message: '{_field_} maksimal {length} karakter.',
});

extend('address', value => {
  if (/^[\w\s\d,.-]+$/.test(value)) {
    return true;
  }

  return '{_field_} hanya boleh huruf, angka, spasi, titik dan koma';
});

extend('have_lat_long', (value, ...a) => {
  if (value.match(/\/@(.*)\//)) return true;

  return 'URL yang Anda masukkan salah, mohon menyalin link URL peta lokasi dari search bar google maps.';
});
