<template>
  <bg-field class="search-checkbox" :label="label">
    <bg-dropdown
      class="search-checkbox__dropdown"
      v-model="showDropdown"
      :menu-placement="dropdownMenuPlacement"
      :menu-size="dropdownMenuSize"
      scrollable
    >
      <template #trigger>
        <div class="search-checkbox__trigger">
          <bg-text size="body-2">
            <span
              v-show="
                selectedItemsToShow === 'label' ? checkedTotal === 0 : true"
              :class="{
                'search-checkbox__trigger-text--placeholder':
                  checkedTotal === 0,
              }"
            >
              {{ placeholder }}
            </span>

            <bg-badge-counter
              class="search-checkbox__trigger-counter"
              v-if="selectedItemsToShow === 'total-item' && isShowCounter"
              v-show="checkedTotal"
              :value="checkedTotal"
              variant="black"
              :max="maxCounterDisplay"
            />

            <span v-else>
              {{ selectedList }}
            </span>
          </bg-text>

          <bg-icon
            class="search-checkbox__trigger-icon"
            name="dropdown-down"
            size="sm"
          />
        </div>
      </template>

      <div
        data-testid="search-checkbox-dropdown"
        class="search-checkbox__content"
      >
        <template v-if="isLoadingList">
          <div
            class="search-checkbox__content-loading-info"
            v-if="!disableMax || !disableClear"
          >
            <div>
              <bg-skeleton v-if="!disableMax" width="100px" height="21px" />
            </div>
            <bg-skeleton v-if="!disableClear" width="40px" height="21px" />
          </div>

          <bg-skeleton
            v-if="!disableSearch"
            width="100%"
            height="46px"
            class="search-checkbox__content-loading-search"
          />

          <div class="search-checkbox__content-loading-content">
            <bg-skeleton v-for="i in 3" :key="i" width="100%" height="24px" />
          </div>
        </template>

        <template v-else>
          <header
            class="search-checkbox__header"
            v-if="!disableMax || !disableClear"
          >
            <bg-text size="body-2">
              <span v-if="!disableMax">Pilih Maksimal {{ maxChecked }}</span>
            </bg-text>
            <bg-link
              v-if="!disableClear"
              role="button"
              @click="clearChecked"
              class="search-checkbox__clear"
            >
              <bg-text size="body-4">Hapus</bg-text>
            </bg-link>
          </header>

          <bg-input
            v-if="!disableSearch"
            class="search-checkbox__searchbar"
            v-model="searchInput"
            :placeholder="searchPlaceholder"
            @input="handleFiltering"
          />

          <template v-if="categoryLabel.length">
            <div v-for="(category, index) in categoryLabel" :key="index">
              <bg-text size="title-4" class="search-checkbox__category-label">
                {{ category.label }}
              </bg-text>

              <bg-checkbox
                class="search-checkbox__checkbox"
                v-for="(item, itemId) in processedList.filter(
                  item => item.type === category.value
                )"
                :id="`search-checkbox-${name}-${itemId}-${item.value}`"
                :key="`search-checkbox-${name}-${itemId}-${item.value}`"
                :label="item.label"
                :value="item.value"
                :disabled="isItChecked(item.value) && disableCheckbox"
                v-model="checkedArray"
                @input="emitCheckedArray"
              />
            </div>
          </template>

          <div v-else-if="selectType === 'multiple'">
            <bg-checkbox
              v-if="processedList.length && hasSelectAllOption"
              v-model="isAllSelected"
              id="search-checkbox-select-all"
              :label="selectAllOptionLabel"
              class="search-checkbox__checkbox"
              @input="onSelectAllCheckboxClick"
            />

            <bg-checkbox
              class="search-checkbox__checkbox"
              v-for="(item, index) in processedList"
              :id="`search-checkbox-${name}-${index}`"
              :key="`search-checkbox-${name}-${index}`"
              :label="item.label"
              :value="item.value"
              :disabled="isItChecked(item.value) && disableCheckbox"
              v-model="checkedArray"
              @input="emitCheckedArray"
            />
          </div>

          <div v-else-if="selectType === 'single'">
            <bg-link
              variant="high-naked"
              class="search-checkbox__list-no-icon"
              role="button"
              v-for="(item, index) in processedList"
              :id="`search-checkbox-${name}-${index}`"
              :key="`search-checkbox-${name}-${index}`"
              @click="emitSelectedListing(item.value)"
            >
              {{ item.label }}
            </bg-link>
          </div>

          <div class="search-checkbox__not-found" v-if="!processedList.length">
            {{ placeholder }} tidak ditemukan
          </div>
        </template>
      </div>
    </bg-dropdown>
  </bg-field>
</template>

<script>
import {
  BgDropdown,
  BgField,
  BgIcon,
  BgBadgeCounter,
  BgSkeleton,
  BgText,
  BgCheckbox,
  BgInput,
  BgLink,
} from 'bangul-vue';

export default {
  name: 'SearchCheckbox',

  components: {
    BgDropdown,
    BgField,
    BgIcon,
    BgBadgeCounter,
    BgSkeleton,
    BgText,
    BgCheckbox,
    BgInput,
    BgLink,
  },

  props: {
    // Name to differentate to other search checkbox
    name: {
      type: String,
      required: true,
    },
    // This list value is the same format with BgCheckbox list
    // Eg: list: [{ value: 1, label: 'siji' }]
    list: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Placeholder',
    },
    searchPlaceholder: {
      type: String,
      default: 'Cari',
    },
    isLoadingList: {
      type: Boolean,
      default: false,
    },
    maxChecked: {
      type: Number,
      default: 5,
    },
    checked: {
      type: Array,
      required: true,
    },
    dropdownMenuPlacement: {
      type: String,
      default: 'bottom-start',
    },
    dropdownMenuSize: {
      type: String,
      default: 'fixed',
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
    disableClear: {
      type: Boolean,
      default: false,
    },
    // Type of list input type,
    // For multiple select using "checkbox" for single select using "list"
    selectType: {
      type: String,
      default: 'multiple',
    },

    isShowCounter: {
      type: Boolean,
      default: true,
    },
    // Used if has category list label
    // Eg: list: [{ value: 1, label: 'siji' }]
    categoryList: {
      type: Array,
      default: () => [],
    },
    selectedItemsToShow: {
      type: String,
      default: 'total-item',
      validator: type => ['total-item', 'label'].indexOf(type) >= 0,
    },
    hasSelectAllOption: {
      type: Boolean,
      default: false,
    },
    selectAllOptionLabel: {
      type: String,
      default: 'Pilih Semua'
    },
    maxCounterDisplay: {
      type: Number,
      default: 9
    }
  },

  data() {
    return {
      showDropdown: false,
      disableCheckbox: false,
      searchInput: '',
      checkedArray: [],
      processedList: [],
      categoryLabel: [],
      isAllSelected: false
    };
  },

  computed: {
    checkedTotal() {
      return this.checkedArray.length;
    },
    disableMax() {
      return this.maxChecked <= 0;
    },
    selectedList() {
      const selectedItems = this.processedList.filter(item =>
        this.checkedArray.includes(item.value)
      );
      const labels = selectedItems.map(item => item.label);

      return labels.join(", ");
    },
  },

  watch: {
    showDropdown(value) {
      if (!value) {
        this.searchInput = '';
        this.processedList = this.list;
        this.categoryLabel = this.categoryList;

        if (this.checkedArray.length && !this.disableSort) {
          this.sortChecked();
        }
      } else {
        this.$emit('dropdown-open');
      }
    },
    processedList() {
      this.toggleSelectAllCheckbox();
    },
    checkedArray(value) {
      this.disableCheckbox =
        !this.disableMax && value.length >= this.maxChecked;

      this.toggleSelectAllCheckbox();
    },
    checked: {
      immediate: true,
      handler(value) {
        this.checkedArray = value;
      },
    },
    list(value) {
      this.processedList = [...value];
    },
    categoryList(value) {
      this.categoryLabel = [...value];
    },
  },

  created() {
    this.processedList = this.list;
    this.categoryLabel = this.categoryList;
    if (this.checked.length && !this.disableSort) {
      this.sortChecked();
    }
  },

  mounted() {
    window.addEventListener('click', this.blurListener);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.blurListener);
  },

  methods: {
    handleFiltering() {
      const findItems = this.list.filter(item =>
        item.label.toLowerCase().includes(this.searchInput.toLowerCase())
      );

      this.categoryLabel = this.searchInput.length ? [] : this.categoryList;
      this.processedList = findItems;
    },

    sortChecked() {
      const tempList = [...this.processedList];
      const checkedArray = [...this.checkedArray];
      const checkedItems = [];

      checkedArray.forEach(value => {
        tempList.forEach((item, i) => {
          if (item.value === value) {
            checkedItems.push(item);
            tempList.splice(i, 1);
          }
        });
      });

      const uncheckedItems = tempList;

      this.processedList = [...checkedItems, ...uncheckedItems];
    },

    clearChecked() {
      this.checkedArray = [];
      this.emitCheckedArray();
    },

    blurListener(e) {
      /*
        Make sure we run these code
        only if the clicked area isn't this component
        since we're listening to window
      */
      const isThisComponent = !this.$el.contains(e.target);
      if (isThisComponent) {
        this.showDropdown = false;
      }
    },

    isItChecked(value) {
      return this.checkedArray.findIndex(item => item === value) === -1;
    },

    emitCheckedArray() {
      this.$emit('emit-checked-array', this.checkedArray);
    },

    emitSelectedListing(listId) {
      this.showDropdown = false;
      this.$emit('emit-checked-array', [listId]);
    },

    toggleSelectAllCheckbox() {
      this.isAllSelected =
        this.checkedArray.length === this.processedList.length;
    },

    onSelectAllCheckboxClick(isSelectAll) {
      this.isAllSelected = isSelectAll;

      if (isSelectAll) {
        this.checkedArray = this.processedList.map(item => item.value);
      } else {
        this.checkedArray = [];
      }

      this.emitCheckedArray();
    }
  },
};
</script>

<style lang="scss" src="./SearchCheckbox.scss" />
