<template>
  <div class="form-property-profile">
    <bg-skeleton
      v-if="loadingFetchData"
      width="40%"
      :height="40"
      data-testid="form-property-profile-loader"
    />
    <template v-else>
      <bg-text size="heading-2" class="mb-32">
        {{ xPropertyDetail.name }}
      </bg-text>
      <bg-text size="heading-4" class="mb-32"> Profil Properti </bg-text>
    </template>

    <ValidationObserver ref="observer">
      <bg-grid>
        <bg-grid-item :col="6" class="mb-32">
          <bg-field label="ID" class="mb-0">
            <bg-skeleton v-if="loadingFetchData" width="100%" :height="50" />
            <bg-input
              v-else
              v-model="property.id"
              disabled
              data-testid="input-property-id"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <ValidationProvider
            name="Nama Properti"
            :rules="`required|max:${maxPropertyNameLength}`"
            v-slot="{ errors }"
          >
            <bg-field
              label="Nama Properti"
              class="mb-0"
              :error="!!errors.length"
              :message="errors[0]"
            >
              <bg-skeleton v-if="loadingFetchData" width="100%" :height="50" />
              <bg-input
                v-else
                v-model="property.name"
                class="form-property-profile__input-prefix"
                placeholder="Masukkan nama properti"
                :prefix="property.prefix"
                data-testid="input-property-name"
              />
            </bg-field>
          </ValidationProvider>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <bg-field label="Jenis Produk" class="mb-0">
            <bg-skeleton v-if="loadingFetchData" width="100%" :height="50" />
            <bg-input
              v-else
              v-model="property.product"
              placeholder="Masukkan jenis produk"
              disabled
              data-testid="input-property-product"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <bg-field label="Tipe" class="mb-0">
            <bg-skeleton v-if="loadingFetchData" width="100%" :height="50" />
            <bg-input
              v-else
              v-model="property.unit_type"
              placeholder="Masukkan tipe"
              disabled
              data-testid="input-property-type"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <bg-skeleton v-if="loadingFetchData" width="100%" :height="80" />
          <template v-else>
            <search-checkbox
              label="Syarat Pekerjaan Penyewa"
              name="job-options"
              :list="occupationLists"
              :checked="property.occupation_requirement"
              :max-checked="0"
              placeholder="Pilih Syarat Pekerjaan Penyewa"
              dropdown-menu-size="fit-to-trigger"
              disable-search
              disable-clear
              data-testid="input-property-job-options"
              @emit-checked-array="setSelectedValueTo('job', $event)"
            />
          </template>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <bg-skeleton v-if="loadingFetchData" width="100%" :height="80" />
          <template v-else>
            <search-checkbox
              label="Syarat Agama Penyewa"
              name="religion-options"
              :list="religionLists"
              :checked="property.religion_requirement"
              :max-checked="0"
              placeholder="Pilih Syarat Agama Penyewa"
              dropdown-menu-size="fit-to-trigger"
              disable-search
              disable-clear
              data-testid="input-property-religion-options"
              @emit-checked-array="setSelectedValueTo('religion', $event)"
            />
          </template>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <ValidationProvider
            name="Alamat"
            rules="required|address"
            v-slot="{ errors }"
          >
            <bg-field
              label="Alamat"
              class="mb-0"
              :error="!!errors.length"
              :message="errors[0]"
            >
              <bg-skeleton v-if="loadingFetchData" width="100%" :height="80" />
              <bg-textarea
                v-else
                v-model="property.address"
                placeholder="Masukkan Alamat"
                :has-counter="false"
                data-testid="input-property-address"
              />
            </bg-field>
          </ValidationProvider>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-32">
          <ValidationProvider
            name="Peta Lokasi"
            rules="required|have_lat_long"
            v-slot="{ errors }"
          >
            <bg-field
              label="Peta Lokasi"
              class="mb-0"
              :error="!!errors.length"
              :message="errors[0]"
            >
              <bg-skeleton v-if="loadingFetchData" width="100%" :height="80" />
              <bg-textarea
                v-else
                v-model="property.maps_link"
                placeholder="Masukkan peta lokasi"
                :has-counter="false"
                data-testid="input-property-maps"
              />
            </bg-field>
          </ValidationProvider>
        </bg-grid-item>
      </bg-grid>
    </ValidationObserver>

    <bg-grid class="mt-32">
      <bg-grid-item :col="12">
        <bg-button
          variant="primary"
          :disabled="loadingFetchData"
          :loading="loadingSaveData"
          data-testid="save-btn"
          @click="checkingAllForm"
        >
          Simpan
        </bg-button>
      </bg-grid-item>
    </bg-grid>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import {
  BgGrid,
  BgGridItem,
  BgInput,
  BgField,
  BgText,
  BgButton,
  BgTextarea,
  BgSkeleton,
} from 'bangul-vue';
import SearchCheckbox from '@admin_molecules/SearchCheckbox';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { LOADING_STATE } from '@admin_store/modules/propertyDetail';
import './veeValidateCustomRules';
import occupationLists from './data/occupationOptionLists.json';
import religionLists from './data/religionOptionLists.json';
import propertyDetail from '@/_admin/api/endpoints/property-detail';

const MAX_PROPERTY_NAME_LENGTH_FROM_BE = 255;
const fieldId = {
  name: 'Nama Properti',
  address: 'Alamat',
  maps_link: 'Peta Lokasi',
};

export default {
  name: 'FormPropertyProfile',

  components: {
    BgGrid,
    BgGridItem,
    BgInput,
    BgField,
    BgText,
    BgButton,
    BgTextarea,
    BgSkeleton,
    SearchCheckbox,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      loadingSaveData: false,
      property: {
        id: '',
        name: '',
        unit_type: '',
        product: '',
        address: '',
        maps_link: '',
        occupation_requirement: [],
        religion_requirement: [],
      },
      occupationLists: [],
      religionLists: [],
    };
  },

  computed: {
    ...mapState('propertyDetail', ['xPropertyDetail', 'xLoadingState']),

    ...mapGetters('propertyDetail', ['xPropertyDetailUnitType']),

    propertyId() {
      return this.$route.params.propertyId;
    },

    loadingFetchData() {
      return this.xLoadingState === LOADING_STATE.FETCHING;
    },

    maxPropertyNameLength() {
      const lastSpaceLength = 1;
      const productCharLength =
        (this.property.prefix ? this.property.prefix.length : 0) +
        lastSpaceLength;

      return MAX_PROPERTY_NAME_LENGTH_FROM_BE - productCharLength;
    },
  },

  created() {
    this.mappingDropdownOptionData();
    this.fetchPropertyProfile();
  },

  methods: {
    ...mapActions('propertyDetail', ['xConsumePropertyDetail']),
    ...mapMutations('breadcrumb', ['updateBreadcrumb']),

    mappingDropdownOptionData() {
      const mappingValue = value =>
        value.map(val => ({ label: val, value: val }));

      this.occupationLists = mappingValue(occupationLists);
      this.religionLists = mappingValue(religionLists);
    },

    async fetchPropertyProfile() {
      if (this.xLoadingState !== LOADING_STATE.FETCH_DONE) {
        await this.xConsumePropertyDetail(this.propertyId);
      }

      this.mappingPropertyData(this.xPropertyDetail);

      this.updateBreadcrumb({
        index: 1,
        item: {
          name: this.xPropertyDetail.name,
          url: `/property-detail/${this.propertyId}/overview`,
        },
      });
    },

    mappingPropertyData(property) {
      this.property = {
        ...property,
        ...this.splitPropertyName(property),
        unit_type: this.xPropertyDetailUnitType,
        occupation_requirement: this.validateValue(
          property.occupation_requirement,
          occupationLists
        ),
        religion_requirement: this.validateValue(
          property.religion_requirement,
          religionLists
        ),
      };
    },

    validateValue(value = [], existingValue) {
      return value.filter(val => existingValue.includes(val));
    },

    splitPropertyName(property) {
      const regex = new RegExp(`(kos(t?) ${property.product}) (.*)`, 'i');
      const splitName = property.name.match(regex);

      if (splitName) {
        const propertyName = splitName[splitName.length - 1];
        const prefix =
          splitName[1] === propertyName
            ? `Kos ${property.product}`
            : splitName[1];

        return { name: propertyName, prefix };
      }

      return {
        name: '',
        prefix: '',
      };
    },

    async checkingAllForm() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) this.submitData();
    },

    async submitData() {
      const data = {
        name: `${this.property.prefix} ${this.property.name}`,
        occupation_requirement: this.property.occupation_requirement,
        religion_requirement: this.property.religion_requirement,
        address: this.property.address,
        maps_link: this.property.maps_link,
      };
      this.loadingSaveData = true;

      try {
        await propertyDetail.updatePropertyProfile(this.property.id, data);
        this.$toast.show('Perubahan berhasil disimpan.');
        this.$router.push({
          name: 'property-detail.overview',
          params: {
            propertyId: this.propertyId,
          },
        });
      } catch (error) {
        const errorDetails = error?.response?.data.issue?.details;
        if (errorDetails) {
          const errors = this.errorMessageParser(errorDetails);

          this.$refs.observer.setErrors(errors);
        } else {
          this.$toast.show('Terjadi galat, silahkan coba beberapa saat lagi.');
        }
      } finally {
        this.loadingSaveData = false;
      }
    },

    errorMessageParser(errorDetails) {
      const errors = {};

      Object.entries(errorDetails).forEach(([field, messages]) => {
        if (fieldId[field]) {
          errors[fieldId[field]] = messages;
        }
      });

      return errors;
    },

    setSelectedValueTo(field, value) {
      switch (field) {
        case 'job':
          this.property.occupation_requirement = [...value];
          break;

        case 'religion':
          this.property.religion_requirement = [...value];
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./FormPropertyProfile.scss"></style>
